/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

body {
   background-color: #000;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: "Manrope", sans-serif !important;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.fade-up {
   opacity: 0;
   animation: fade-up 2s ease-out forwards;
}

@keyframes fade-up {
   0% {
      opacity: 0;
      transform: translateY(300px);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
}

.fade-right {
   opacity: 0;
   animation: fade-right 2s ease-out forwards;
}

@keyframes fade-right {
   0% {
      opacity: 0;
      transform: translateX(200%);
   }
   100% {
      opacity: 1;
      transform: translateX(0);
   }
}

::-webkit-scrollbar {
   width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
   background: #000; /* Or any other color you prefer */
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: #373737; /* Color of the scrollbar handle */
   border-radius: 5px; /* Roundness of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: #555; /* Darker color when hovered */
}
